import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../layouts/layout.js'
import Pagetitle from '../components/Pagetitle'

const PortfolioPage = ({ data }) => {
  const portfolio = data.datoCmsPortfolio

  return (
    <Layout>
      <Helmet>
        <title>{portfolio.title} | Portfolio | Wayward Media | Sowerby Bridge</title>
        <meta name="description" content="description" />
      </Helmet>
      <Pagetitle title={portfolio.title} />
      <div className="p-3">{portfolio.content}</div>
      <a className="text-primary font-semibold hover:text-primarylighter" href={portfolio.url} target="_blank" rel="noopener noreferrer">
        {portfolio.url.replace(/^\/\/|^.*?:(\/\/)?/, '')}
      </a>
    </Layout>
  )
}

export const PortfolioGraph = graphql`
  query PortfolioQuery($slug: String!) {
    datoCmsPortfolio(slug: { eq: $slug }) {
      title
      url
      content
    }
  }
`

export default PortfolioPage
